@import '../_vars';
.years-manager{
  width: 100%;
  height: auto;
  min-height: 100%;
  padding-top: 1rem;
  text-align: center;
  position: relative;
  border-left: 1px solid grey;
  padding-bottom: 105px;     // pour le menu bottom qui cache + add

  display: flex;
  flex-direction: column;

  .bottom-navigation{
    position: fixed;
    bottom: 0;
    width: 100vw;
    overflow-x: auto; // si jamais ca deborde
    .MuiBottomNavigation-root{
      background: $color-white;
    }

    .fab{
      margin-bottom: 0.5rem;
    }
    .MuiBottomNavigationAction-root{
      padding-left: 0;
      padding-right: 0;
      min-width: 60px;
      &.opened{
        position: relative;
        &:after{
          content: '';
          display: block;
          width: 12px;
          height: 12px;
          background: red;
          position: absolute;
          top: 8px;
          right: 8px;
          border-radius: 50%;
        }
      }
    }
  }

  .help{
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;

    .fab{
      width: 30px;
      height: 30px;
      min-height: 30px;
      background: $color-white;
      color: $color_text;
    }
  }
}

.year{
  display: inline-block;
  vertical-align: middle;
  background: $color-white;
  padding: 0rem 1rem;
  border-radius: 5px;
  box-shadow: 0 0 5px 5px rgba(0,0,0,0.1);
  cursor: pointer;
  transition: 0.3s;
  text-align: center;
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  font-weight: bold;
  height: 30px;
  line-height: 30px;

  &:hover{
    background: darken($color-white,5%);
  }
}



// maps --------------------
.localization{
  position: absolute;
  top: 0.7rem;
  right: 0.7rem;
}

// harvest -------------------
.harvest-history{
  text-align: left;
  padding: 1rem;

  ul{
    margin: 0 auto;
    padding: 0;
    list-style-position: inside;
    font-size: 0.9rem;
    strong, span{
      padding: 0 0.3rem;
    }
    svg{
      vertical-align: middle;
      margin-right: 1rem;
    }
  }
}

.harvest-total{
  text-align: left;
  padding: 1rem;
  .accordion-family{
    font-weight: bold;
    text-align: left;
    flex-basis: 33%;
    flex-shrink: 0;
  }
  .accordion-total{
    text-align: left;
    @include font-size(14);
    padding-top: 2px;
    strong, span{
      padding-right: 0.3rem;
    }
  }
}

// inventaire -------------
.inventory-opened,
.inventory-history{
  text-align: left;
  padding: 1rem;
}
.inventory-table{
  width: 100%;

  .no-stock{
    font-style: italic;
    opacity: 0.8;
  }

  .green{
    color: green;
  }
}
.inventory-family{
  margin: 0.5rem auto;

  .empty{
    font-style: italic;
    opacity: 0.8;
  }
}
.inventory-history{
  .Mui-expanded{
    .accordion-family{
      font-weight: bold;
      text-decoration: underline;
    }
  }
}

// notes -------------------
.notes-general{
  text-align: left;
  padding: 1rem;

  .MuiFormControl-root{
    width: 100%;
  }
  .MuiInputBase-root{
    width: 100%;
    background: #fff;
  }
}
.notes-history{
  text-align: left;
  padding: 1rem;

  .auto{
    font-style: italic;
    opacity: 0.8;
  }

  ul{
    margin: 0 auto;
    padding: 0;
    list-style-position: inside;
    font-size: 0.9rem;
  }
}