.modal-contents{
  background: #ffffff;
  width: 80%;
  // height: 80%;
  max-width: 800px;
  border-radius: 4px;
  margin: 0 auto;
  padding: 1rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  overflow-y: auto;
  &:focus{
    outline: 0;
  }
  h2{
    text-align: center;
  }
  .modal-form{
    margin-bottom: 50px;
  }
  .modal-actions{
    position: sticky;
    bottom: -1rem;
    width: 100%;
    background: rgba(255,255,255,0.8);
  }

  //
  .fieldRow{
    margin-top: 1rem;
  }


  // label par-dessus
  .MuiInputLabel-outlined{
    background: white;
    padding-bottom: 2px;
    width: calc( 100% - 28px );
    transform: translate(14px, 17px) scale(1);
    height: 20px;
    line-height: 20px;
  }

  // fields pleine largeur
  .MuiFormControl-root,
  .MuiOutlinedInput-root{
    width: 100%;
  }

  .MuiSelect-root .MuiListItemIcon-root{
    font-size: 1em;
    min-width: 30px;
  }
}