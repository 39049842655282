
@import '../../_vars';
.seeds-bag{
  border: 1px solid #333;
  padding: 0.5rem;
  padding-bottom: 1rem; // pour la place des flags
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
  background: $color-white;
  position: relative;

  &.isEmpty{
    opacity: 0.6;
  }
  &.isArchived{
    // opacity: 0.2;
    font-style: italic;
  }

  .flags{
    position: absolute;
    bottom: -0.2rem;
    right: -0.2rem;
    .flag{
      // very small
      height: 18px;
    }
  }

  .date{
    font-size: 0.8rem;
  }

  &:hover{
    background: darken($color-white,5%);
  }
}