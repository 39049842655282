.panels{
  .panels-navigation{
    background: $color_background;
    color: #fff;
    .MuiButtonBase-root{
      opacity: 0.6;
      color: #fff;
      transition: 0.3s;
      &.Mui-selected{
        opacity: 1;
        transform: scale(1.4);
        // text-decoration: underline;
      }
    }
  }
}
