@import '../_vars';
.stocks{
  width: 100%;
  height: auto;
  min-height: 100%;
  padding-top: 1rem;
  text-align: center;
  padding-bottom: 66px;     // pour aerer



  //.families,
  // .varieties{
  //   display: flex;
  //   justify-content: space-around;
  //   align-items: stretch;
  //   flex-wrap: wrap;
  //   clear: both;
  // }
}