$color_background: #e7d9bf;
$color_text: #351706;
$color_secondary: rgba(0, 0, 0, 0.54);
$highlight: #fff9e5;
$color-white: #f1f1f1;

$color-info: #d3e2e2;

// FONT SIZE ----------------------------
$base: 1; // ratio 100% sur html
@mixin font-size($sizeInPx: 16) {
  $remSize: $sizeInPx / 16;
  font-size: #{$sizeInPx}px;
  font-size: #{$remSize}rem;
}
@function pxToRem($sizeInPx) {
  $remSize: $sizeInPx / 16;
  @return #{$remSize}rem;
}