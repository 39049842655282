@import './_vars';

.float-left{
  float: left;
}
.float-right{
  float: right;
}
.reset-float{
  &:after{
    content: '';
    display: block;
    clear: both;
  }
}

.text-right{
  text-align: right;
}
.text-left{
  text-align: left;
}

h1,h2,h3,h4{
  margin: 0;
}

