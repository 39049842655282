@import '../../_vars';
.seedsbags{
  .seeds-bag{
    margin: 0.5rem auto;
    height: calc( 100% - 1rem );
  }

  .empty-message{
    margin: 1rem auto;
  }
}