
@import '../../_vars';
.family{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: $color-white;
  padding: 0.5rem;
  border-radius: 5px;
  box-shadow: 0 0 5px 5px rgba(0,0,0,0.1);
  cursor: pointer;
  transition: 0.3s;
  text-align: center;

  &.without-varieties{
    // .icon,
    // .name{
    //   opacity: 0.5;
    // }
    box-shadow: none;
    font-style: italic;
  }

  .name{
    font-weight: 500;
    text-transform: uppercase;
  }
  .varieties{
    display: block;
    min-height: 20px;
    color: $color_secondary;
  }

  &:hover{
    background: darken($color-white,5%);
  }
}