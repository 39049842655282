@import './_utils';
@import "../node_modules/slick-carousel/slick/slick.css";
@import "../node_modules/slick-carousel/slick/slick-theme.css";

*{
  box-sizing: border-box;
}


@import './_over-Panels';

// content test
.content1,
.content2,
.content3{
  width: 100%;
  height: auto;
  min-height: 100%;
  padding: 1rem;
  text-align: center;
}
.content1{
  border: 1rem solid blue;
}
.content2{
  border: 1rem solid red;
}
.content3{
  border: 1rem solid green;
}