@import '../../_vars';
.paper-note{
  position: relative;
  .MuiInputBase-root.selectfield{
    position: absolute;
    left: 0.2rem;
    bottom: 0.2rem;
    width: 5rem;
    .MuiSelect-root{
      padding: 0.2rem 0.5rem;
      width: auto;
    }
  }
  .remove-notes-btn{
    position: absolute;
    right: 0;
    bottom: 0;
  }

  + .paper-note{
    margin-top: 1rem;
  }
}