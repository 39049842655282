
@import '../../_vars';
.variety{
  display: block;
  text-align: center;
  background: $color-white;
  padding: 1rem;
  padding-bottom: 2rem; // pour la place des flags
  border-radius: 5px;
  box-shadow: 0 0 5px 5px rgba(0,0,0,0.1);
  cursor: pointer;
  transition: 0.3s;
  position: relative;
  @media (min-width: 960px){
    max-width: 50vw;
  }

  .variety-description{
    
  }
  .icon{
    
  }
  .name{
    font-weight: 500;
    text-transform: uppercase;
  }
  .months-indications{
    margin: 1rem auto;
  }
  .months-indication{
    text-align: left;
  }


  .flags{
    position: absolute;
    bottom: 0.2rem;
    right: 0.2rem;
    .flag{
      // very small
      height: 18px;
    }
  }

  &.isEmpty{
    box-shadow: none;
  }

  &:hover{
    background: darken($color-white,5%);
  }
}