@import '../../_vars';
.families{
  .family{
    margin: 0.5rem;
    height: calc( 100% - 1rem );
  }

  .empty-message{
    margin: 1rem auto;
  }
}