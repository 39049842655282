@import '../_vars';
.dashboard{
  width: 100%;
  height: auto;
  min-height: 100%;
  padding-top: 1rem;
  text-align: center;
  position: relative;
  border-left: 1px solid grey;

  display: flex;
  flex-direction: column;

  .slider-by-month{
    flex: 1;

    display: flex;
    flex-direction: column;
    .slick-slider{
      flex: 1;
    }
    .slick-prev{
      left: 1rem;
      top: 1rem;
      width: 2rem;
      height: 2rem;
      z-index: 1;
      &:before{
        font-size: 2rem;
      }
    }
    .slick-next{
      right: 1rem;
      top: 1rem;
      width: 2rem;
      height: 2rem;
      z-index: 1;
      &:before{
        font-size: 2rem;
      }
    }
    .slick-dots{
      bottom: 66px; // pour passer au-dessus des tabs (56 + marge)
    }
    .slick-dots li{
      margin: 0;
    }
    .slick-dots li button{
      width: 10px;
    }
  }


  .iframe{
    width: 100%;
    text-align: left;
    iframe{
      width: 100%;
      border: 0;
    }
  }

  .bottom-navigation{
    position: fixed;
    bottom: 0;
    width: 100vw;
    .MuiBottomNavigation-root{
      background: $color-white;
    }
  }
}